import React from 'react';

import {Helmet} from 'react-helmet';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-153071844-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class ComingSoon extends React.Component {
	componentDidMount(){
		
	}

	render(){
		return(
			<section>
			<Helmet>
		    	<title>Coming Soon | Andrea Kushh</title>
		    	<meta name="description" content="Andrea Kushh. Ux Designer" />
		  	</Helmet>
			<div style={{backgroundColor: "black"}}>
				<div className="row" style={{backgroundColor: "black"}}>
		          <div id="coming_soon_frame_2" className="col-lg-12" style={{height: "100vh", backgroundColor: "black"}}>
					<svg id="working_on_it_svg" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
						 preserveAspectRatio="xMidYMin slice" x="0" y="0" width="100%"
						 viewBox="0 0 1702 1346" 
						 xmlSpace="preserve">
						<defs>
							<style dangerouslySetInnerHTML = {{__html: `
						      .cls-1,.cls-2{fill:none;}.cls-2{stroke-miterlimit:10;stroke-width:3px;stroke:url(#linear-gradient);}.cls-3{fill:url(#linear-gradient-2);}.cls-4{clip-path:url(#clip-path);}.cls-5,.cls-6{fill-rule:evenodd;}.cls-5{fill:url(#Degradado_sin_nombre_27);}.cls-6{fill:#fff;}
						    `}}>
						    </style>
							<linearGradient id="linear-gradient" x1="1601.52" y1="673.09" x2="104.37" y2="673.09" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#662d91"/><stop offset="0.13" stopColor="#5c419b"/><stop offset="0.39" stopColor="#4375b7"/><stop offset="0.76" stopColor="#1bc7e2"/><stop offset="1" stopColor="aqua"/></linearGradient>
							<linearGradient id="linear-gradient-2" x1="852.95" y1="784.51" x2="852.95" y2="561.67" gradientTransform="translate(1483 -212) rotate(87)" xlinkHref="#linear-gradient"/>
							<clipPath id="clip-path">
								<circle className="cls-1" cx="853" cy="673" r="111" transform="matrix(0.05, -1, 1, 0.05, 138.41, 1491.63)"/>
							</clipPath>
							<linearGradient id="Degradado_sin_nombre_27" x1="870.21" y1="667.25" x2="1068.11" y2="667.25" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#643289"/><stop offset="0.25" stopColor="#64358b"/><stop offset="0.43" stopColor="#623f90"/><stop offset="0.44" stopColor="#623f90"/><stop offset="0.58" stopColor="#5f4393"/><stop offset="0.72" stopColor="#58509b"/><stop offset="0.87" stopColor="#4c65a8"/><stop offset="1" stopColor="#3d7eb8"/>
							</linearGradient>
						</defs>
						{/*<rect id="coming_soon_frame" className="cls-2" x="106" y="101" width="1494" height="1143.6"/>*/}
						
						{/*<circle id="coming_soon_circle" class="cls-3" cx="853" cy="673" r="111" transform="matrix(0.05, -1, 1, 0.05, 138.41, 1491.63)"/>*/}
  						{/*<text transform="matrix(1 0 0 1 91.5449 64.4707)" fill="url(#linear-gradient-2)" x="433" y="903" font-family="'ArialMT'" font-size="52.1954">We are currently working on it</text>*/}
  						<text transform="matrix(1 0 0 1 91.5449 64.4707)" fill="white" x="463" y="923" fontFamily="'Proxima Nova'" fontSize="52.1954">Website under construction</text>
						<g className="cls-4">
							<path className="cls-5" d="M0,0ZM0,0ZM0,0ZM0,0Z"/>
							<path className="cls-6" d="M0,0ZM0,0Z"/>
						</g>
					</svg>
		          </div>
		        </div>
			</div>
			</section>
		);
	}
}

export default (ComingSoon);