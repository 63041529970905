import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import 'core-js/es/number'; 

// core components
import GeneralLayout from "layouts/GeneralLayout.js"; //Defines how the general layout of the webpage will behave...
//  
import ComingSoon from "views/ServerStatus/ComingSoon.js";
import NotFound from "views/ServerStatus/NotFound.js";
import Home from "views/Custom/Home.js";

import 'assets/css/general.css';
import "assets/css/material-dashboard-react.css?v=1.8.0";
import 'bootstrap/dist/css/bootstrap.min.css';  

const hist = createBrowserHistory();

// Heavily inspired of Material UI:
// @see https://github.com/mui-org/material-ui/blob/9cf73828e523451de456ba3dbf2ab15f87cf8504/src/styles/createGenerateClassName.js

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {/*For the REAL routes being used, search for routes.js*/}
      <Route path="/" component={GeneralLayout}/>
      <Route path="/coming_soon" exact={true} component={ComingSoon}/>
      <Route path="/not_found" component={NotFound}/>
      {/*<Route path="/home" exact={true} component={Home}/>
      <Redirect path="/home" to="/" />
      <Redirect path="/*" to="/home" />*/}
    </Switch>
  </Router>,
  document.getElementById("root")
);