import React from "react"
import { Redirect, NavLink } from "react-router-dom";

import Particles from 'react-particles-js';

import { ReactComponent as DeerSVG } from "assets/svg/small_deer.svg"

class Ann extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount(){

  }

  componentWillUnmount() {

  }

  render(){
    return(
      <div>
        <div className="row" >
        	<div className="col-sm-12" style={{backgroundColor: "black"}}>
        		<Particles
				    params={{
					    "fps_limit": 28,
					    "particles": {
					        "collisions": {
					            "enable": false
					        },
					        "number": {
					            "value": 200,
					            "density": {
					                "enable": false
					            }
					        },
					        "line_linked": {
					            "enable": true,
					            "distance": 30,
					            "opacity": 0.4
					        },
					        "move": {
					            "speed": 1
					        },
					        "opacity": {
					            "anim": {
					                "enable": true,
					                "opacity_min": 0.05,
					                "speed": 1,
					                "sync": false
					            },
					            "value": 0.4
					        }
					    },
					    "polygon": {
					        "enable": true,
					        "scale": 0.5,
					        "type": "inline",
					        "move": {
					            "radius": 10
					        },
					        "url": "/small_deer.svg",
					        "inline": {
					            "arrangement": "equidistant"
					        },
					        "draw": {
					            "enable": true,
					            "stroke": {
					                "color": "white"
					            }
					        }
					    },
					    "retina_detect": false,
					    "interactivity": {
					        "events": {
					            "onhover": {
					                "enable": true,
					                "mode": "bubble"
					            }
					        },
					        "modes": {
					            "bubble": {
					                "size": 6,
					                "distance": 40
					            }
					        }
					    }
					}} 
				/>
        	</div>
        </div>
      </div>
    )
  }
}
export default Ann;
