import React from 'react';

class NotFound extends React.Component {
	componentDidMount(){
		
	}

	render(){
		return(
			<section>
				<div>
					<div className="row">
						Not Found
					</div>
				</div>
			</section>
		);
	}
}

export default (NotFound);