import React from "react"
import { Redirect, NavLink } from "react-router-dom";
import {Helmet} from "react-helmet";

import ScrollContainer from 'react-indiana-drag-scroll'
import { gsap, TweenLite, TimelineMax, TimelineLite } from 'gsap';
import {Tween} from 'react-gsap';
import { CSSRulePlugin, TextPlugin, CSSPlugin } from "gsap/all";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { UncontrolledTooltip, Tooltip } from 'reactstrap';
import emailjs from 'emailjs-com';
import axios from 'axios';
import ReactCountdownClock  from 'react-countdown-clock';
import Badge from '../../components/Badge/Badge.js';

import { ReactComponent as LinkedInSVG } from "assets/svg/linkedin.svg"
import { ReactComponent as ResumeSVG } from "assets/svg/resume.svg"
import { ReactComponent as MailSVG } from "assets/svg/mail.svg"
import { ReactComponent as ArrowSVG } from "assets/svg/down_arrow_indicator.svg"
import AndreaKushhImg from "assets/img/andreakushh_panel.png"
import DielectricSensorImg from "assets/img/dielectric_sensor_panel_img.png"
import CstImg from "assets/img/cst_panel_img.png"
import AnnImg from "assets/img/neural_network_panel_img.png"
import ElectVehImg from "assets/img/electric_vehicle_img.png"
import CV from "assets/Resume_JAMJ_ENG-SPA.pdf"
import loader from "assets/img/loader.gif"
import Timer from "../../components/Custom/Timer.js"

import 'assets/css/general.css';

gsap.registerPlugin(CSSPlugin, CSSRulePlugin)

gsap.registerPlugin(TextPlugin);

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayProject: false,
      dragMouseOn: true,
      editingModal: false,
      emailTooltip: false,
      isIE: false,
      isMobile: false,
      mouseDown: false,
      numberProjects: 4, 
      performance: true,     
      projects: [],
      selectedProject: 1,
      sendingEmail: false,
      showModal: false,
      verticalScroll: true,
    };
    this.performancet0 = performance.now();
    this.cursorMeasures = [
      {viewport: 1280, width: 50, height: 50, exploreBtnWidth: "12%", refMeasuresDivider: 13, exploreCaseX: "-15.5vw", lineIndicatorWidth: "36%", contactHoverOffset: 3.5, expCaseHoverOffset: 12.5,},
      {viewport: 1366, width: 50, height: 50, exploreBtnWidth: "12%", refMeasuresDivider: 13, exploreCaseX: "-15vw", lineIndicatorWidth: "40%", contactHoverOffset: 6, expCaseHoverOffset: 12.5,},
      {viewport: 1920, width: 80, height: 80, exploreBtnWidth: "14%", refMeasuresDivider: 13, exploreCaseX: "-15vw", lineIndicatorWidth: "40%", contactHoverOffset: 1.5, expCaseHoverOffset: 11.1,}
    ];

    this.dragCursor = this.dragCursor.bind(this);
    this.getViewportElementMeasurements = this.getViewportElementMeasurements.bind(this);
    this.msieversion = this.msieversion.bind(this);

    this.mouseDown = this.mouseDown.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.mouseUpFinished = this.mouseUpFinished.bind(this);
    this.hoverCursor = this.hoverCursor.bind(this);
    this.leaveCursor = this.leaveCursor.bind(this);
    this.handleHoverProject = this.handleHoverProject.bind(this);
    this.handleListHover = this.handleListHover.bind(this);
    this.handlePanelHover = this.handlePanelHover.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleExploreCase = this.handleExploreCase.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    this.testDiv1 = React.createRef();
    this.testDiv2 = React.createRef();
    this.testDiv3 = React.createRef();
    this.testDiv4 = React.createRef();
    this.testDiv5 = React.createRef();
    this.testDiv6 = React.createRef();
    this.caseRef1 = React.createRef();
    this.caseRef2 = React.createRef();
    this.caseRef3 = React.createRef();
    this.caseRef4 = React.createRef();
    this.caseRef5= React.createRef();
    this.caseRef6 = React.createRef();

    this.cursorHoverRef = React.createRef();
    this.cursorRef = React.createRef();
    this.mailRef = React.createRef();
    this.linkedinRef = React.createRef();
    this.cvRef = React.createRef();
    this.demoBtnRef = React.createRef();
    this.closeBtnRef = React.createRef();
    this.panelContArrRef = {};
    this.testDivArr = {};//Dinamically created REF array for projectDivs. Created on MAP
  }

  componentDidMount(){
    this.prevY = 0;
    this.lastEvent = null;
    this.lastEventButton = null;
    this.mouseDownAnimComplete = true;
    this.tlMouseDown = new TimelineLite();
    this.tlMouseUp = new TimelineLite();

    this.closestPosY = 10000; //Random HIGH Y position.
    this.closestProject = 1;
    //alert(window.innerWidth);
    //alert(window.innerHeight);

    //Catalogue use: Biomedics - Web Development - Software Development - Mechatronics
    this.setState({projects: [
      {id: 1, refObj: this.testDiv1, refBtn: this.caseRef1, name: "Dielectric Sensor", cat: "Biomedics | 2019 - Active",
        img: DielectricSensorImg,
        descr: "Msc. Biomedical Engineering thesis project. \n \n A low-cost, portable dielectric sensor, based on a parallel-plate cell design was developed, with which a study of dielectric spectroscopy at 1 MHz was carried out in blood samples. These samples were obtained from patients with ALL and from a group of healthy individuals in order to detect irregularities related to acute lymphoblastic leukaemia. \n \n This sensor is suitable to perform analyses either on blood or other types of samples. \n \n A more specific relative permittivity value for a healthy female blood sample at 1MHz was shown. A speculative value for ALL female blood samples with an average age of 12 years was described as well. \n \n The dielectric spectroscopy analyses were performed at the UNE-UPAEP Cancer Research Center.",
        tech: [["Python","React.js", "Node.js", "Websockets", "Bootstrap"],["MySQL", "AWS", "Git", "C", "SolidWorks"],["Multisim", "UX"]],
        link: null,
      },
      {id: 2, refObj: this.testDiv2, refBtn: this.caseRef2, name: "CST Project", cat: "Web Development | 2017 - 2019",
        img: CstImg, 
        descr: "Software Dev position. \n \n Design and development of a Flight Management System web app: \n - A whole intelligent quotation system for airports and aeronautical regulations. \n - Interface for calculating and displaying the optimal aeronautical route for flying between 2 airports, showing also the countries and FIR airspaces that will be flown over. \n - Forms for requesting different types of services. \n - Section for displaying important information and regulations from countries and airports. \n - CRUD's in general. \n - Database design. \n - Backend in general.",
        tech: [["React.js", "Redux", "Bootstrap", "AWS", "MySQL"],["Git", "Laravel", "RESTful", "UX", "SCRUM"],["Google Maps", "SSL"]],
        link: "https://marvelapp.com/prototype/bgga37b/screen/57004069",
      },
      {id: 3, refObj: this.testDiv3, refBtn: this.caseRef3, name: "ANN Project", cat: "Software Development | 2018",
        img: AnnImg, 
        descr: "Msc. Biomedical Engineering. Artificial Neural Networks course final project. \n \n Problem analysis, design and implementation of an artificial neural network algorithm in order to detect and mitigate certain cybersecurity threats. This ANN was designed to identify malicious URL’s given that they may cause a major issue in certain environments, for example, in a company. \n \n An ADALINE architecture was implemented. 9 inputs were used, which corresponded to 9 different parameters that were related to the URL (anchor URL, request URL, SFH, URL length, pop-up window, website traffic, domain age, IP, SSL). 3 neurons were used in order to classify the URL as legitimate, suspicious, and malicious.",
        tech: [["Matlab"],[],[]],
        link: null,
      },
      {id: 4, refObj: this.testDiv4, refBtn: this.caseRef4, name: "Electric Vehicle", cat: "Mechatronics | 2016",
        img: ElectVehImg, 
        descr: "Mechatronic Design course final project. \n \n Electric vehicle state of the art review. Analysis of specialized components, technical specifications and review of which were the most used ones in the current market. \n \n Given a Dodge Attitude 2011 vehicle, the main objective of this project, was to analyse the whole vehicle, with real physical and electrical measurements, components, electrical systems, power consumption, autonomy, etc. in order to design a “conversion kit” that would transform that (internal combustion) Dodge Attitude into a feasible and competitive (in both, autonomy and price) electric vehicle. \n \n Special emphasis was made in the components’ assembly area, as well as in choosing the most efficient DC motor, DC controller, DC/DC converter and batteries.  Real market suppliers were contacted as well. \n \n A whole custom lithium-ion batteries module was designed in order to lower the prices and maximize autonomy. \n \n A physical analysis of the car was performed as well in order to ensure that the new components and distribution (new weight and placement) would not affect the correct functioning of the vehicle. \n \n A ‘QFD’ and ‘FODA’ analyses were carried out in order to compare and contrast against the existing ‘electric conversion kits’ in the market.",
        tech: [["SolidWorks"],[],[]],
        link: null,
      },
    ]}); 
    /*
      {id: 2, refObj: this.testDiv2, refBtn: this.caseRef2, name: "Andrea Kushh", cat: "Web Development | 2020 - Active",
        img: AndreaKushhImg,
        descr: "Msc. Biomedical Engineering thesis project.",
        tech: [["React.js", "AWS", "CSS Grid", "SSL","Git"],[],[]],
        link: "https://andreakushh.com/",
      },
      {id: 3, refObj: this.testDiv3, refBtn: this.caseRef3, name: "Casai", cat: "Web Development | 2020",
        img: null, 
        descr: "Msc. Biomedical Engineering thesis project.",
        tech: [["Python","React.js", "Node.js", "Websockets", "Bootstrap"],[],[]],
        link: "https://andreakushh.com/",
      },
    */
    //Inital project to mid screen position
    //var projMidYPos = (((this.testDiv1.getBoundingClientRect().height)/2)+this.testDiv1.getBoundingClientRect().y);
    //var screenMidYPos = (window.innerHeight)/2;
    //var offsetToScroll = Math.abs(screenMidYPos-projMidYPos);
    //gsap.to('.scroll-container',{
    //  scrollTop: 100,
    //});
    window.addEventListener('scroll', e => this.handleScroll(e), true);

    //Down arrow indicator animation each 5 sec
    var downArrowIndicator = window.setInterval(function(){
      var tlDownArrow = new TimelineLite();

      tlDownArrow.to(`#down_arrow_indicator_figure`,{y: "40%",}, "anim1");
      tlDownArrow.to(`#down_arrow_indicator_figure`,{y: "0%",}, "anim2");
      tlDownArrow.to(`#down_arrow_indicator_figure`,{y: "40%",}, "anim3");
      tlDownArrow.to(`#down_arrow_indicator_figure`,{y: "0%",}, "anim4");
    }, 5000);

    //Contact tooltip lets talk anim
    var tempThis = this;//workaround to use 'this' inside of the UNBINDED function
    var contatTooltipAnim = window.setInterval(async function(){
      tempThis.setState({emailTooltip: true});
      await new Promise(r => setTimeout(r, 5000));
      tempThis.setState({emailTooltip: false});
    }, 20000);

    this.performancet1 = performance.now();
    //Taking about 48-53 ms on a GOOD laptop
    //Taking about 135 ms on a low budget 2016 laptop
    if((this.performancet1 - this.performancet0) > 100){//If the computer is slow, disable grain effect...
      this.setState({performance: false});
    }

    if(this.msieversion()){
      this.setState({isIE: true});
    }else if(window.innerWidth < 500){
      this.setState({isMobile: true});
    }
    console.log(`[INFO]Initial rendering took... ${this.performancet1 - this.performancet0} milliseconds.`);
  }

  componentWillUnmount() {

  }

  msieversion() 
  {
      //console.log(window.navigator.userAgent);
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf('MSIE ');
      if (msie > 0) {
          // IE 10 or older => return version number
          //console.log(parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10))
          return true;
      }

      var trident = ua.indexOf('Trident/');
      if (trident > 0) {
          // IE 11 => return version number
          var rv = ua.indexOf('rv:');
          //console.log(parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10))
          return true;
      }

      return false;
  }

  downloadFile(url){
    var file_path = url;
    var a = document.createElement('A');
    a.href = file_path;
    a.setAttribute('target','_blank');
    a.download = "Resume_JAMJ_ENG-SPA.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  handleScroll = (e) => {
    //console.log('test');
    const rule = CSSRulePlugin.getRule("#indicator");
    //console.log( rule );
    //Gives effect of NON LINEAR scroll  
    if((this.state.mouseDown)&&(e.target.scrollTop>this.prevY))
      e.target.scrollTop = e.target.scrollTop+14;
    else if((this.state.mouseDown)&&(e.target.scrollTop<this.prevY)){
      e.target.scrollTop = e.target.scrollTop-14;
    }
    this.prevY = e.target.scrollTop;
  }

  dragCursor(e){
    gsap.to(this.cursorRef,{
      x: e.clientX,
      y: e.clientY,
    });
    gsap.to(this.cursorHoverRef,{
      x: e.clientX,
      y: e.clientY,
    });

    //Blur-reveal effect using cursor of the CURRENT selected project
    var clipPathElem = this.panelContArrRef[this.state.selectedProject-1].getBoundingClientRect();
    gsap.to(`.panel_img`, { clipPath: "ellipse(100px 100px at "+(e.clientX-clipPathElem.left)+"px "+(e.clientY-clipPathElem.top)+"px"});
  }

  getViewportElementMeasurements(){
    //alert(window.innerWidth);
    if((window.innerWidth >= 1280)&&(window.innerWidth <= 1365)){//HD 1280
      return 0;
    }else if((window.innerWidth >= 1366)&&(window.innerWidth <= 1919)){//Most common 1366
      return 1;
    }else if((window.innerWidth >= 1920)){//Full HD 1920
      return 2;
    }
  }

  mouseDown(e){
    if(this.state.isIE){
      return 0;
    }

    console.log("[EVENT] On mouse down");
    var evt = e || window.event;
    //If LEFT click...
    if(evt.buttons == 1 && this.mouseDownAnimComplete == true){
      this.mouseDownAnimComplete = false;
      this.setState({mouseDown: true});
      var tlCursor = new TimelineLite();
      //let upArrow = CSSRulePlugin.getRule(".cursor:before"); //AVOID CSSRulePlugin USE css VARIABLES
      //let downArrow = CSSRulePlugin.getRule(".cursor:after"); //AVOID CSSRulePlugin USE css VARIABLES
      let buttonLine = CSSRulePlugin.getRule(".projectButtonLi:before");

      //gsap.to(upArrow,0.3, {cssRule: { y: "115px", transform: "rotate(180deg)"}})
      //gsap.to(downArrow,0.3, {cssRule: { y: "15px"}})
      gsap.to(this.cursorRef,0.3,{scale: 0.85,});
      gsap.to("html", {"--cursorUpArrowTransform": "translate(0px, -15px) rotate(180deg)", ease:"Expo.easeIn"});
      gsap.to("html", {"--cursorDownArrowTransform": "translate(0px, 15px)", ease:"Expo.easeIn"});

      this.tlMouseDown.to(`.project_content`,0.2, { opacity: "0", display: "none"}, "anim1");
      this.tlMouseDown.to(`.projectHeader`,0.4, {y: '-30px',opacity: 0, ease:"Expo.easeIn"}, "anim1");
      this.tlMouseDown.to(`.projectTitle>h1`,0.4, {width: '0%', scale: 1, ease:"Expo.easeIn"}, "anim1");
      //tlDisplayProject.to(`.projectTitle>h1`,0.2, { width: "0", paddingLeft: "0", scale: 0.7,}, "a4");
      //this.tlMouseDown.to(buttonLine,0.4, {cssRule: { width: "0%", ease:"Expo.easeIn" }}, "anim1");
      this.tlMouseDown.to("html",0.4, {"--lineIndicatorWidth": "0%", ease:"Expo.easeIn"}, "anim1");
      this.tlMouseDown.to(`.projectButton`,0.4, {x: "-100px", opacity: 0, ease:"Expo.easeIn" }, "anim1");

      //this.tlMouseDown.to(`.project-div`,0.3, {height: '40%', width: '70%', opacity: 1, x: 0});
      this.tlMouseDown.to(`.projectTitle`,0.4, {y: '24%', ease:"Expo.easeIn"}, "anim2");
      this.tlMouseDown.to(`.project-div`,0.3, {scaleY:0.6, width: '70%', opacity: 1, x: 0}, "anim2");
      this.tlMouseDown.to(`.panel_img`,0.3, {scaleY:1.6}, "anim2");
      this.tlMouseDown.set(".project-div", {className:"project-div transition"});
      //this.tlMouseDown.to(`.test-row`,0.3, {y: '100px', onComplete: () => this.mouseDownAnimComplete = true});//Last anim to be executed
      
      //gsap.to('.project-div',{scale: 0.95,x: 0,opacity: 1,});
      
      this.lastEventButton = e.buttons;
      this.lastEvent = e;
      //gsap.to(buttonLine,0.5, {cssRule: { width: "0%", ease:"Expo.easeIn" }});
      //gsap
      //.to(`.projectButtonLi`,0.5, {width: "-100px", ease:"Expo.easeIn" });
    }
  }

  //ULTIMATE click released. All back to normal view
  mouseUpFinished(distToScollY, projectPanelMidYPos) {
    //alert(document.getElementsByClassName('scroll-container')[0].scrollTop);
    //alert(this.state.mouseDown);
    console.log("[EXEC] MouseUpFinished");
    if(!this.state.mouseDown){
      this.tlMouseDown.clear();
      this.mouseDownAnimComplete = true;
      var screenMidYPos = ((window.innerHeight)/2);
      var scrollDir = Math.round(screenMidYPos-projectPanelMidYPos);
      var projectArr = [this.testDiv1, this.testDiv2, this.testDiv3, this.testDiv4];
      let indicator = CSSRulePlugin.getRule("#indicator:before");
      let indicatorNum = CSSRulePlugin.getRule("#indicator:after");
      let upArrow = CSSRulePlugin.getRule(".cursor:before");
      let downArrow = CSSRulePlugin.getRule(".cursor:after");
      let buttonLine = CSSRulePlugin.getRule(".projectButtonLi:before");
      var vpElMsArIndex = this.getViewportElementMeasurements();

      gsap.set(".project-div", {className:"project-div"});

      //gsap.to(`.project-div.active`,0.3, {opacity: 1});

      //gsap.to(`.test-row`,0.3, {y: '0px'});
      gsap.to(`.project-div`,0.3, {scaleY:1, x: "0%", width: '87%'});
      gsap.to(`.panel_img`,0.3, {scaleY:1}, "anim2");
      gsap.to(`.projectTitle`,0.5, {y: '0%', x: '2%', ease:"Expo.easeIn"});
      gsap.to(`.panel_img`,0.2, { opacity: 1,});
      gsap.to(`.projectTitle>h1`,0.5, {left: "-25%", width: '130%', scale: 1, textShadow: "-6px 6px 15px rgba(0,0,0,0.5),6px -6px 15px rgba(255,255,255,0.8)", boxShadow: " 12px 12px 16px 0 rgb(0 0 0 / 25%)"});
      gsap.to(`.projectHeader`,0.5, {y: '0px',opacity: 1, ease:"Expo.easeIn"});
      gsap.to(`.projectButtonLi`,0.5, {display: "list-item",opacity: 1});
      gsap.to(`.projectButton`,0.5, {text: "0"+this.state.selectedProject+"",x: "0px", opacity: 1, ease:"Expo.easeIn" })
      //gsap.to(buttonLine,0.5, {cssRule: { width: "25%", ease:"Expo.easeIn" }});
      gsap.to("html",0.5, {"--lineIndicatorWidth": this.cursorMeasures[vpElMsArIndex].lineIndicatorWidth, ease:"Expo.easeIn"});
      gsap.to(`.project_content`,0.2, { opacity: "0", display: "none"}, "anim1");
      gsap.to(`.helper_div`,0.1, {opacity: 1});

      gsap.to("html", {"--cursorUpArrowTransform": "translate(0px, 0px) rotate(180deg)", ease:"Expo.easeIn"});
      gsap.to("html", {"--cursorDownArrowTransform": "translate(0px, 0px)", ease:"Expo.easeIn"});
      //gsap.to(upArrow,0.5, {cssRule: { y: "0px", transform: "rotate(180deg)", ease:"Expo.easeIn" }});
      //gsap.to(downArrow,0.5, {cssRule: { y: "0px", ease:"Expo.easeIn" }});
                
      if(Math.sign(scrollDir) === 1){
        distToScollY = -distToScollY;
      }else if(Math.sign(scrollDir) === -1){
        distToScollY = distToScollY;
      }else{
        distToScollY = 0;
      }
      //alert(document.getElementsByClassName('scroll-container')[0].scrollTop+distToScollY);
      gsap.to('.scroll-container',{scrollTop: document.getElementsByClassName('scroll-container')[0].scrollTop+distToScollY,});
      //gsap.to('.project-div',{
       // scale: 1,
      //});
      gsap.to('.inactive',{
        x: -200,
        opacity: 0,
      });
      gsap.to(this.cursorRef,{
        scale: 1,
      });
      //To ADD or SUBSTRACT more projects: -Modify the #projects in STATE. -Mod project catalogue. 
      //-goto "switch (this.state.selectedProject)", Divide 39% / #projects. -CHANGE the #indicator::before as well (in general.css). 
      //Indicator animation controller. 
      switch (this.state.selectedProject) {
        case 1:
          gsap.to(indicator,1, {cssRule: { height: "9.75%", ease:"Expo.easeIn" }, duration: 0.3});
          gsap.to(`#indicator_container>span#span1`, {text: "01.", ease:"Expo.easeIn", duration: 0.2});
          break;
        case 2:
          gsap.to(indicator,1, {cssRule: { height: "19.5%", ease:"Expo.easeIn" }, duration: 0.3});
          gsap.to(`#indicator_container>span#span1`, {text: "02.", ease:"Expo.easeIn", duration: 0.2});
          break;
        case 3:
          gsap.to(indicator,1, {cssRule: { height: "29.25%", ease:"Expo.easeIn" }, duration: 0.3});
          gsap.to(`#indicator_container>span#span1`, {text: "03.", ease:"Expo.easeIn", duration: 0.2});
          break;
        case 4:
          gsap.to(indicator,1, {cssRule: { height: "39%", ease:"Expo.easeIn" }, duration: 0.3});
          gsap.to(`#indicator_container>span#span1`, {text: "04.", ease:"Expo.easeIn", duration: 0.2});
          break;
      }
      /*case 5:
          gsap.to(indicator,1, {cssRule: { height: "31.6665%", ease:"Expo.easeIn" }, duration: 0.3});
          gsap.to(`#indicator_container>span#span1`, {text: "05.", ease:"Expo.easeIn", duration: 0.2});
          break;
        case 6:
          gsap.to(indicator,1, {cssRule: { height: "39%", ease:"Expo.easeIn" }, duration: 0.3});
          gsap.to(`#indicator_container>span#span1`, {text: "06.", ease:"Expo.easeIn", duration: 0.2});
          break;
      */
    }
  }

  mouseUp(e){
    if(this.state.isIE){
      return 0;
    }
    console.log("[EVENT] On mouse up");
    //gsap.to(e.target,{
    //  scrollTop: 100,
    //});
    var screenMidYPos = ((window.innerHeight)/2);
    //var projectArr = [this.testDiv1, this.testDiv2, this.testDiv3, this.testDiv4];
    var projectArr = this.testDivArr;
    this.closestPosY = 10000;

    for (var i = 1; i <= this.state.numberProjects; i++) {
      //console.log(projectArr[i-1].getBoundingClientRect().height);
      var projectPanelMidYPos = (((projectArr[i-1].getBoundingClientRect().height)/2)+projectArr[i-1].getBoundingClientRect().y);
      //console.log('P'+i+' pos: '+projectPanelMidYPos);
      var distToScollY = Math.abs(screenMidYPos - projectPanelMidYPos);
      //alert('P'+i+' missing scroll: '+distToScollY);
      //console.log(this.closestPosY);
      if(distToScollY <= this.closestPosY){
        this.closestPosY = distToScollY;
        this.closestProject = i;
      }
    }
    this.lastEvent = e;
    this.setState({selectedProject: this.closestProject,mouseDown: false}, () => {this.mouseUpFinished(this.closestPosY, (((projectArr[this.closestProject-1].getBoundingClientRect().height)/2)+projectArr[this.closestProject-1].getBoundingClientRect().y))});
  }

  hoverCursor(e,ref,isButton,isDemoLink,isCloseButton){
    console.log("[EVENT] hoverCursor");
    gsap.to(`.cursor`,0.2, {text: "", ease:"Expo.easeIn"});
    //console.log(ref);
    if(ref){
      this.setState({dragMouseOn: false,})
      var refMeasures = ref.current.getBoundingClientRect();
      let upArrow = CSSRulePlugin.getRule(".cursor:before");
      let downArrow = CSSRulePlugin.getRule(".cursor:after");
      var vpElMsArIndex = this.getViewportElementMeasurements();

      gsap.to(upArrow,0.5, {cssRule: { opacity: "0", ease:"Expo.easeIn" }});
      gsap.to(downArrow,0.5, {cssRule: { opacity: "0", ease:"Expo.easeIn" }});

      if(isButton){//Change cursor to rectangle if hovering the Explore Case button
        gsap.to(this.cursorRef,{x: (refMeasures.x+refMeasures.width/2)-(refMeasures.x/this.cursorMeasures[vpElMsArIndex].expCaseHoverOffset),y: (refMeasures.y+refMeasures.height/2)+2,});
        gsap.to(`.cursor`,0.3, {borderRadius: "0%",text: "", width: this.cursorMeasures[vpElMsArIndex].exploreBtnWidth},);
      }else if(isDemoLink){//Change cursor to underline for the DEMO hiperlink
        gsap.to(this.cursorRef,{x: (refMeasures.x+refMeasures.width/2)-(refMeasures.x/14.8),y: (refMeasures.y+refMeasures.height/2)+2, borderStyle: "none none solid none"});
        gsap.to(`.cursor`,0.3, {borderRadius: "0%",text: "", width: "10%"},);
      }else if(isCloseButton){
        gsap.to(this.cursorRef,{x: (refMeasures.x+refMeasures.width/1.05)-(refMeasures.x/14.8),y: (refMeasures.y+refMeasures.height/2)+2, });
        gsap.to(`.cursor`,0.3, {borderRadius: "0%",text: "", width: "10%"},);
      }
      else{//Contact icons circle
        gsap.to(this.cursorRef,{scale: 1.3,x: (refMeasures.x+refMeasures.width/2)-this.cursorMeasures[vpElMsArIndex].contactHoverOffset,y: (refMeasures.y+refMeasures.height/2)-0,});
      }

    }else{
      gsap.to(this.cursorRef,{
        scale: 2,
        x: e.clientX,
        y: e.clientY,
      });
      gsap.to(this.cursorHoverRef,{
        scale: 2.2,
        x: e.clientX,
        y: e.clientY,
      });
    }
  }

  leaveCursor(e){
    console.log("[EVENT]leaveCursor function")
    this.setState({dragMouseOn: true,})
    let upArrow = CSSRulePlugin.getRule(".cursor:before");
    let downArrow = CSSRulePlugin.getRule(".cursor:after");

    if(!this.state.showModal){
      var vpElMsArIndex = this.getViewportElementMeasurements();

      gsap.to(`.cursor`,0.3, {text: "", borderRadius: "50%", height :this.cursorMeasures[vpElMsArIndex].height, width: this.cursorMeasures[vpElMsArIndex].width, ease:"Expo.easeIn"});
      gsap.to(upArrow,0.5, {cssRule: { opacity: "1", ease:"Expo.easeIn" }});
      gsap.to(downArrow,0.5, {cssRule: { opacity: "1", ease:"Expo.easeIn" }});
      //gsap.to(this.cursorRef,{scale: 1, borderStyle: "solid none solid none"});
      gsap.to(this.cursorRef,{scale: 1, borderStyle: "solid solid solid solid"});
    }
  }

  handleHoverProject(e, isHovered, projectNumb, isButton){
    let buttonLine = CSSRulePlugin.getRule(".projectButtonLi:before");
    let upArrow = CSSRulePlugin.getRule(".cursor:before");
    let downArrow = CSSRulePlugin.getRule(".cursor:after");
    var vpElMsArIndex = this.getViewportElementMeasurements();

    if(isHovered){
      //gsap.to(`.cursor`,{borderColor: 'rgba(255, 245, 247)',});

      //gsap.to(buttonLine,0.4, {cssRule: { width: "0% !important", ease:"Expo.easeIn" }}, "anim1");
      gsap.to("html",0.4, {"--lineIndicatorWidth": "0%", ease:"Expo.easeIn"}, "anim1");
      gsap.to(`.projectButton`,0.3, {text: "Explore Case", x: this.cursorMeasures[vpElMsArIndex].exploreCaseX, ease:"Expo.easeIn"}, "anim1");
      //gsap.to(`.projectButton`,0.5, {x: "0px", opacity: 1, ease:"Expo.easeIn" })
      if(isButton){//Transform cursor into button
        //gsap.to(upArrow,0.5, {cssRule: { opacity: "0", ease:"Expo.easeIn" }});
        //.to(downArrow,0.5, {cssRule: { opacity: "0", ease:"Expo.easeIn" }});
      }
    }else{
      gsap.to(`.cursor`,{borderColor: '#8C0034'});
      gsap.to(upArrow,0.5, {cssRule: { opacity: "1", ease:"Expo.easeIn" }});
      gsap.to(downArrow,0.5, {cssRule: { opacity: "1", ease:"Expo.easeIn" }});
      //gsap.to(buttonLine,0.4, {cssRule: { width: "25%", ease:"Expo.easeIn" }}, "anim1");
      gsap.to("html",0.4, {"--lineIndicatorWidth": this.cursorMeasures[vpElMsArIndex].lineIndicatorWidth, ease:"Expo.easeIn"}, "anim1");
      gsap.to(`.projectButton`,0.3, {text: "0"+projectNumb, x: "0%", ease:"Expo.easeIn"}, "anim1");
    }
  }

  handleModal(e,show){
    if(e.target.id !== "modalLayer" && e.target.id !== "Layer_1" && e.target.id !== "close_modal_btn"){//The only elements that will trigget this funct
      return;
    }
    console.log("[EVENT] handleModal funct");
    let upArrow = CSSRulePlugin.getRule(".cursor:before");
    let downArrow = CSSRulePlugin.getRule(".cursor:after");

    if(show){
      this.setState({showModal: true});
      gsap.to(upArrow,0.5, {cssRule: { opacity: "0", ease:"Expo.easeIn" }});
      gsap.to(downArrow,0.5, {cssRule: { opacity: "0", ease:"Expo.easeIn" }});
      gsap.to(`.cursor`,0.2, {text: "", ease:"Expo.easeIn"});
      gsap.to(`#modalLayer`,{width: '100%',});
      gsap.to(`.scroll-container`,0.4, {delay: 0.1,filter: "blur(10px)"});
      gsap.to(`#modal`, {delay: 0.4,flex: "0 0 50%",});
      gsap.to(`.row.modal_row`, {delay: 0.5,display: "flex", opacity: 1});
      gsap.to(`.cursor`,{borderColor: 'rgba(255, 245, 247)',backgroundColor: 'rgba(255, 245, 247)',});
      gsap.to(this.cursorRef,{scale: 0.4,});
    }else{
      this.setState({showModal: false});
      gsap.to(upArrow,0.5, {cssRule: { opacity: "1", ease:"Expo.easeIn" }});
      gsap.to(downArrow,0.5, {cssRule: { opacity: "1", ease:"Expo.easeIn" }});

      gsap.to(`.row.modal_row`, {display: "none", opacity: 0});

      gsap.to(`.cursor`,0.2, {text: "", ease:"Expo.easeIn"});
      gsap.to(`.cursor`,{borderColor: '#8C0034',backgroundColor: 'transparent',});

      gsap.to(`#modal`, {flex: "0 0 0%",});
      gsap.to(`#modalLayer`,{
        width: '0%',
      });
      gsap.to(`.scroll-container`,0.5, {delay: 0.1,filter: "none"});
      gsap.to(this.cursorRef,{
        scale: 1,
      });
    }
  }

  handlePanelHover(isHovered){
    let upArrow = CSSRulePlugin.getRule(".cursor:before");
    let downArrow = CSSRulePlugin.getRule(".cursor:after");

    if(isHovered){
      this.setState({verticalScroll: false});
      gsap.to(upArrow,0.5, {cssRule: { opacity: "0"}});
      gsap.to(downArrow,0.5, {cssRule: { opacity: "0"}});
      gsap.to(`.cursor`,{borderColor: 'rgb(255, 245, 247)',});
    }else if(!isHovered){
      this.setState({verticalScroll: true});
      gsap.to(upArrow,0.5, {cssRule: { opacity: "1"}});
      gsap.to(downArrow,0.5, {cssRule: { opacity: "1"}});
      gsap.to(`.cursor`,{borderColor: '#8C0034'});
    }
  }

  handleListHover(isHovered){
    let upArrow = CSSRulePlugin.getRule(".cursor:before");
    let downArrow = CSSRulePlugin.getRule(".cursor:after");

    if(isHovered){
      this.setState({verticalScroll: false});
      gsap.to(upArrow,0.5, {cssRule: { opacity: "0"}});
      gsap.to(downArrow,0.5, {cssRule: { opacity: "0"}});
    }else if(!isHovered){
      this.setState({verticalScroll: true});
      gsap.to(upArrow,0.5, {cssRule: { opacity: "1"}});
      gsap.to(downArrow,0.5, {cssRule: { opacity: "1"}});
    }
  }

  handleExploreCase(project, isOpen){
    this.setState({verticalScroll: false, displayProject: true,});
    var tlDisplayProject = new TimelineLite();
    let upArrow = CSSRulePlugin.getRule(".cursor:before");
    let downArrow = CSSRulePlugin.getRule(".cursor:after");

    gsap.to(upArrow,0.5, {cssRule: { opacity: "0"}});
    gsap.to(downArrow,0.5, {cssRule: { opacity: "0"}});
    tlDisplayProject.to(`.projectButtonLi`,0.1, {display: "none",opacity: 0}, "a1");
    tlDisplayProject.to(`.projectHeader`,0.1, {opacity: 0}, "a1");
    tlDisplayProject.to(`.panel_img`,0.1, { opacity: 0,}, "a1");
    tlDisplayProject.to(`.helper_div`,0.1, {opacity: 0}, "a2");
    tlDisplayProject.to(`.project-div`,0.5, {x: "-5%",width: "205%", scaleY: 1.25,}, "a2");
    tlDisplayProject.to(`.cursor`,{borderColor: 'rgba(255, 245, 247)'}, "a3"); 
    tlDisplayProject.to(`.projectTitle`,0.3, { y: "-64%",}, "a3");
    tlDisplayProject.to(`.projectTitle>h1`,0.3, { paddingLeft: "0", textShadow: "-6px 6px 15px rgba(0,0,0,0.5)", boxShadow: "none"}, "a3");
    tlDisplayProject.to(`.projectTitle`,0.2, { x: "-70.5%",}, "a4");
    tlDisplayProject.to(`.projectTitle>h1`,0.2, { left: "-56.5%", width: "200%", paddingLeft: "0", scale: 0.7,}, "a4");
    tlDisplayProject.to(`.row.project_content`,0.2, { opacity: "1", display: "flex"}, "a5");
  }

  handleValidSubmit = async (event, values) => {
    event.preventDefault();
    this.setState({sendingEmail: true});
    //console.log("[EVENT] START Requesting public ip...")
    const publicIp = require('public-ip');
    var ip = await publicIp.v4();
    //console.log("[EVENT] DONE Requesting public ip.")

    if(ip)
      values.ip = ip;
    else{
      values.ip = null;
    }

    values.browserInfo = navigator.userAgent;
    //console.log(values);
    var msg = JSON.stringify(values);
    axios.post('https://h7omlyf0gi.execute-api.us-east-1.amazonaws.com/default/sendEmail', msg)
    .then((response) => {
      console.log(response);
      alert("Success. Your message has been sent!");
      this.setState({sendingEmail: false}, () => {
          gsap.to(`.row.modal_row`, {delay: 0.5,display: "flex", opacity: 1})
      });
    }, (error) => {
      console.log(error);
      alert("Your message can not be delivered right now. Please try again later.");
      this.setState({sendingEmail: false}, () => {
          gsap.to(`.row.modal_row`, {delay: 0.5,display: "flex", opacity: 1})
      });
    });
  }

  render(){
    //console.log(this.lastEvent);      
    //var projectArr = [this.testDiv1, this.testDiv2, this.testDiv3, this.testDiv4];
    return(
      <div>
        <Helmet>
            <title>ALEJANDROMJ | Frontend Dev Freelance Portfolio</title>
            <meta name="description" content="Freelance developer. Experience and professional interest in the development of projects related to front-end development, biomedical engineering, control systems, artificial neural networks, and design and implementation of electronic interfaces, in sectors such as biomedicine, aeronautics, cybersecurity and electric vehicles design." />
            <meta name="keywords" content="biomedical, engineer, frontend, fullstack, developer, freelance, dielectric properties, blood relative permittivity, jesus martinez"/>
            <meta name="author" content="Jesús Alejandro Martínez Juárez" />
            <meta http-equiv="cache-control" content="no-cache"/>
        </Helmet>
        {((!this.state.isIE)&&(!this.state.isMobile)) ? 
        <div id="modalLayer" onClick={(e) => this.state.editingModal ? this.handleModal(e,true) : this.handleModal(e,false)} onMouseMove={this.state.dragMouseOn ? this.dragCursor : null} style={{}}>
          <div className="row" style={{height: 0}}>
            <div className="col-sm-3" style={{height: 0}}>
            </div>
            <div id="modal" className="col-sm-6" onMouseEnter={() => this.setState({editingModal: true})} onMouseLeave={() => this.setState({editingModal: false})}>
              {!this.state.sendingEmail ? 
              <AvForm onValidSubmit={this.handleValidSubmit}>
                <div className="row modal_row">
                  <div className="col-sm-12" style={{textAlign: "center"}}>
                    <ul className="list-none">
                      <li><p style={{color: "rgb(255, 245, 247)"}}>Want to bring an idea to life? Let's work together!</p></li>
                    </ul>
                  </div>
                </div>
                <div id="modal_overflow">
                <div className="row modal_row">
                  <div className="col-sm-4">
                    <AvField name="firstName" label="First Name *" placeholder="John" type="text" className="primary_input" required />
                  </div>
                  <div className="col-sm-4">
                    <AvField name="lastName" label="Last Name *" placeholder="Doe" type="text" className="primary_input" required />
                  </div>
                </div>
                <div className="row modal_row">
                  <div className="col-sm-4">
                    <AvField name="email" label="Email *" type="email" placeholder="myemail@gmail.com" className="primary_input" required />
                  </div>
                  <div className="col-sm-4">
                    <AvField name="phone" label="Phone" type="number" placeholder="eg. (999)999 9999" className="primary_input" />
                  </div>
                </div>
                <div className="row modal_row">
                  <div className="col-sm-12">
                    <AvField name="message" label="Message *" type="textarea" rows="3" placeholder="Write your message here..." className="primary_input" required />
                  </div>
                </div>
                <div className="row modal_row action_button_row">
                  <div className="col-sm-3" style={{textAlign: "center"}}>
                    <button className="pr_primary_btn">Submit</button>
                  </div>
                  <div className="col-sm-6">
                    
                  </div>
                  <div className="col-sm-3" style={{textAlign: "center"}}>
                    <button id="close_modal_btn" className="pr_primary_btn" type="button" onMouseEnter={() => this.setState({editingModal: false})} onMouseLeave={() => this.setState({editingModal: true})} onClick={(e) => this.handleModal(e,false)}>Close</button>
                  </div>
                </div>
              </div>
              </AvForm>
              :
              <div class="loadingio-spinner-dual-ring-n1trspcz7t">
                <div class="ldio-lbln0584m6g">
                  <div></div>
                  <div><div></div></div>
                </div>
              </div>
              }
            </div>
            <div className="col-sm-3" style={{height: 0}}>
            </div>
          </div>
        </div>
        : null}
        {((!this.state.isIE)&&(!this.state.isMobile)) ? 
        <div id="header_contact_progress_layout">
          <div id="header" className="row">
            <div className="col-sm-2">
              
            </div>
            <div className="col-sm-4">
            </div>
            <div className="col-sm-6">
             
            </div>
          </div>
          <div className="contact_sidebar">
            <ul className="list-none contact_sidebar_list">
              <li>
                <a href="#" ref={this.mailRef} onMouseEnter={(e) => {this.hoverCursor(e,this.mailRef)}} onMouseLeave={this.leaveCursor} onClick={(e) => this.handleModal(e,true)}>
                  <figure className="contact_sidebar_icons"><MailSVG/></figure>
                  <Tooltip placement="right" isOpen={this.state.emailTooltip} target="Layer_1" id="contact_tooltip">
                    Let's talk!
                  </Tooltip>
                </a>
              </li>
              <li><a href="#" ref={this.cvRef} onClick={() => this.downloadFile(CV)} onMouseEnter={(e) => this.hoverCursor(e,this.cvRef)} onMouseLeave={this.leaveCursor}><figure className="contact_sidebar_icons"><ResumeSVG/></figure></a></li>
              <li><a href="https://www.linkedin.com/in/alejandromj-/" target="_blank" ref={this.linkedinRef} onMouseEnter={(e) => this.hoverCursor(e,this.linkedinRef)} onMouseLeave={this.leaveCursor}><figure className="contact_sidebar_icons"><LinkedInSVG/></figure></a></li>
            </ul>
          </div>
          <div id="content">
            
          </div>
          <div id="down_arrow_indicator">
            {this.state.selectedProject === 6 ? 
              null
              :
              <figure id="down_arrow_indicator_figure">
                <ArrowSVG/>
                <UncontrolledTooltip placement="top" target="down_arrow_indicator_svg" id="down_arrow_tooltip">
                  Drag and drop cursor
                </UncontrolledTooltip>
              </figure>
            }
          </div>
          <div id="indicator_container">
            <span id="span1">01.</span>
            <div id="indicator"></div>
            <span id="span2">0{this.state.numberProjects}.</span>
            <span id="drag_drop_span"><p>DRAG &<br/>DROP</p></span>
          </div>
        </div>
        : null}
        {!this.state.isMobile ? 
          <div id="noiseLayer" onMouseMove={this.state.dragMouseOn ? this.dragCursor : null} onMouseDown={this.state.verticalScroll ? this.mouseDown : null} onMouseUp={this.state.verticalScroll ? this.mouseUp : null}>
          {!this.state.isIE ? 
            <ScrollContainer className={this.state.performance ? "scroll-container grain" : "scroll-container"} horizontal={false} vertical={this.state.verticalScroll}>
              {/*START 1st Project*/}
              {/*<div className="row test-row">
                <div className="col-md-2"></div>
                <div className="col-md-4">
                  <div className={`project-div ${this.state.selectedProject === 1 ? "active" : "inactive"}`} ref={div => this.testDiv1 = div} onMouseEnter={(e) => {this.handleHoverProject(e,true); this.handlePanelHover(true)}} onMouseLeave={(e) => {this.handlePanelHover(false)}}  style={{}}>

                  </div>
                </div>
                <div className="col-md-4">
                  <ul className="list-none project_list" onMouseEnter={(e) => {this.handleListHover(true)}} onMouseLeave={(e) => {this.handleListHover(false)}}>
                    <li className="projectHeader" onMouseEnter={(e) => {this.setState({verticalScroll: false})}}><h4>Biomedics | 2019 - Active</h4></li>
                    <li className="projectTitle" onMouseEnter={(e) => {this.setState({verticalScroll: false})}} onMouseLeave={(e) => {this.setState({verticalScroll: true})}}><h1 onMouseEnter={(e) => {this.handleHoverProject(e,true);this.setState({verticalScroll: false})}} onMouseLeave={(e) => {this.setState({verticalScroll: true})}}>NEUMORPHIC</h1></li>
                    <li className="projectButtonLi" onMouseEnter={(e) => {this.handleHoverProject(e,true,null,true);}} onMouseLeave={(e) => {}}><button ref={this.caseRef} onClick={(e) => this.handleExploreCase(1,true)} className="projectButton" onMouseEnter={(e) => {this.hoverCursor(e,this.caseRef,true);this.setState({verticalScroll: false})}} onMouseLeave={(e) => {this.leaveCursor(); this.setState({verticalScroll: true}); this.handleHoverProject(e,false,1)}}>01</button></li>
                  </ul>
                </div>
                <div className="col-md-2"></div>
              </div>*/}
              {/*END 1st Project*/}

              {/*START Iterate an render all the projects*/}
              {this.state.projects.map((project, index) => (
              <div className="row test-row">
                <div className="col-md-2"></div>
                <div className="col-md-4">
                  <div className={`project-div ${this.state.selectedProject === project.id ? "active" : "inactive"}`} ref={ref => this.testDivArr[index] = ref} onMouseEnter={(e) => {this.handleHoverProject(e,true); this.handlePanelHover(true)}} onMouseLeave={(e) => {this.handlePanelHover(false)}}  style={{}}>
                    {/*<span class="helper"></span><img className="panel_img" ref={ref => this.panelContArrRef[index] = ref} src={project.img} alt="" height="100"/>*/}
                    <div className={`helper_div project_${project.id}`}>
                      <img className="panel_img" ref={ref => this.panelContArrRef[index] = ref} src={project.img}></img>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <ul className={`list-none project_list project_${project.id}`} onMouseEnter={(e) => {this.handleListHover(true)}} onMouseLeave={(e) => {this.handleListHover(false)}}>
                    <li className="projectHeader" onMouseEnter={(e) => {this.setState({verticalScroll: false})}}><h4>{project.cat}</h4></li>
                    <li className="projectTitle" onMouseEnter={(e) => {this.setState({verticalScroll: false})}} onMouseLeave={(e) => {this.setState({verticalScroll: true})}}><h1 onMouseEnter={(e) => {this.handleHoverProject(e,true);this.setState({verticalScroll: false})}} onMouseLeave={(e) => {this.setState({verticalScroll: true})}}>{project.name}</h1></li>
                    <li className="projectButtonLi" onMouseEnter={(e) => {this.handleHoverProject(e,true,null,true);}} onMouseLeave={(e) => {}}><button ref={project.refBtn} onClick={(e) => this.handleExploreCase(project.id,true)} className="projectButton" onMouseEnter={(e) => {this.hoverCursor(e,project.refBtn,true);this.setState({verticalScroll: false})}} onMouseLeave={(e) => {this.leaveCursor(); this.setState({verticalScroll: true}); this.handleHoverProject(e,false,project.id)}}>0{project.id}</button></li>
                  </ul>
                </div>
                <div className="col-md-2"></div>
              </div>
              ))}
              {/*END Iterate an render all the projects*/}

              {/*START 2nd Project*/}
              {/*<div className="row test-row">  
                <div className="col-md-2"></div>
                <div className="col-md-4">
                  <div className={`project-div ${this.state.selectedProject === 2 ? "active" : "inactive"}`} ref={div => this.testDiv2 = div} onMouseEnter={(e) => this.handleHoverProject(e,true)} onMouseLeave={(e) => this.handleHoverProject(e,false,2)} style={{}}>

                  </div>
                </div>
                <div className="col-md-4">
                  <ul className="list-none project_list">
                    <li className="projectHeader"><h4>Biomedics ----- 2019 - Active</h4></li>
                    <li className="projectTitle"><h1>Title</h1></li>
                    <li className="projectButtonLi"><button className="projectButton">02</button></li>
                  </ul>
                </div>
                <div className="col-md-2"></div>
              </div>*/}
              {/*END 2nd Project*/}
              {/*START 3nd Project*/}
              {/*<div className="row test-row">
                <div className="col-md-2"></div>
                <div className="col-md-4">
                  <div className={`project-div ${this.state.selectedProject === 3 ? "active" : "inactive"}`} ref={div => this.testDiv3 = div} onMouseEnter={(e) => this.handleHoverProject(e,true)} onMouseLeave={(e) => this.handleHoverProject(e,false,3)} style={{}}>

                  </div>
                </div>
                <div className="col-md-4">
                  <ul className="list-none project_list">
                    <li className="projectHeader"><h4>Biomedics ----- 2019 - Active</h4></li>
                    <li className="projectTitle"><h1>Title</h1></li>
                    <li className="projectButtonLi"><button className="projectButton">03</button></li>
                  </ul>
                </div>
                <div className="col-md-2"></div>
              </div>*/}
              {/*END 3nd Project*/}
              {/*START 4nd Project*/}
              {/*<div className="row test-row">
                <div className="col-md-2"></div>
                <div className="col-md-4">
                  <div className={`project-div ${this.state.selectedProject === 4 ? "active" : "inactive"}`} ref={div => this.testDiv4 = div} onMouseEnter={(e) => this.handleHoverProject(e,true)} onMouseLeave={(e) => this.handleHoverProject(e,false,4)} style={{}}>

                  </div>
                </div>
                <div className="col-md-4">
                  <ul className="list-none project_list">
                    <li className="projectHeader"><h4>Biomedics ----- 2019 - Active</h4></li>
                    <li className="projectTitle"><h1>Title</h1></li>
                    <li className="projectButtonLi"><button className="projectButton">02</button></li>
                  </ul>
                </div>
                <div className="col-md-2"></div>
              </div>*/}
              {/*END 4nd Project*/}
              <div className="row project_content" style={{display: "none"}}>
                <div className="col-md-2"></div>
                <div className="col-md-8 project_overview">
                  <ul className="list-none overflow_list" onMouseEnter={(e) => {this.handlePanelHover(true)}} onMouseLeave={(e) => {this.handlePanelHover(false)}}>
                    <li className="project_description" >
                    {this.state.projects.[(this.state.selectedProject)-1] ? 
                      <p>{this.state.projects.[(this.state.selectedProject)-1].descr}</p>
                      :
                      null
                    }
                    </li>
                    <li className="" >
                      <div className="row">
                        <div className="col-sm-3">
                          <ul className="list-none">
                            <li className="" style={{color: "rgb(255, 245, 247)"}}>Developed with:</li>
                            {this.state.projects.[(this.state.selectedProject)-1] ? 
                              this.state.projects.[(this.state.selectedProject)-1].tech[0].map((tech, index) => (
                                <li className="badge_li" ><Badge className="test_badge" color="primary">{tech}</Badge></li>
                              ))
                              :
                              null
                            }
                          </ul>
                        </div>
                        <div className="col-sm-3">
                          <ul className="list-none">
                            <li className="" style={{color: "transparent"}}>Technologies:</li>
                            {this.state.projects.[(this.state.selectedProject)-1] ? 
                              this.state.projects.[(this.state.selectedProject)-1].tech[1].map((tech, index) => (
                                <li className="badge_li" ><Badge color="primary">{tech}</Badge></li>
                              ))
                              :
                              null
                            }
                          </ul>
                        </div>
                        <div className="col-sm-3">
                          <ul className="list-none">
                            <li className="" style={{color: "transparent"}}>Technologies:</li>
                            {this.state.projects.[(this.state.selectedProject)-1] ? 
                              this.state.projects.[(this.state.selectedProject)-1].tech[2].map((tech, index) => (
                                <li className="badge_li" ><Badge color="primary">{tech}</Badge></li>
                              ))
                              :
                              null
                            }
                          </ul>
                        </div>
                      </div>
                    </li>
                    {(this.state.projects.[(this.state.selectedProject)-1] && this.state.projects.[(this.state.selectedProject)-1].link) ? 
                      <li id="" className="demo_btn_li" ><a className="demo_btn" ref={this.demoBtnRef} target="_blank" href={this.state.projects.[(this.state.selectedProject)-1].link} onMouseEnter={(e) => {this.hoverCursor(e, this.demoBtnRef, false, true);this.setState({verticalScroll: false})}} onMouseLeave={(e) => {this.leaveCursor();}}>Live Demo</a></li>
                      :
                      null
                    }
                    {/*<li id="close_project_btn_li" className="" ><button id="close_project_btn" ref={this.closeBtnRef} onMouseEnter={(e) => {this.hoverCursor(e, this.closeBtnRef, false, false, true);this.setState({verticalScroll: false})}} onMouseLeave={(e) => {this.leaveCursor();}} onClick={(e) => {this.mouseUp();this.setState({verticalScroll: true});this.handleHoverProject(null,false,this.state.selectedProject)}}>Close</button></li>*/}
                  </ul>
                  <ul className="list-none" onMouseEnter={(e) => {this.handlePanelHover(true)}} onMouseLeave={(e) => {this.handlePanelHover(false)}}>
                    <li id="close_project_btn_li" className="" ><button id="close_project_btn" ref={this.closeBtnRef} onMouseEnter={(e) => {this.hoverCursor(e, this.closeBtnRef, false, false, true);this.setState({verticalScroll: false})}} onMouseLeave={(e) => {this.leaveCursor();}} onClick={(e) => {this.mouseUp();this.setState({verticalScroll: true});this.handleHoverProject(null,false,this.state.selectedProject)}}>Close</button></li>
                  </ul>
                </div>
                <div className="col-md-2"></div>
              </div>
            </ScrollContainer>
            : 
            null
          }
            {this.state.isIE ? 
              <div id="ie_render" className={this.state.performance ? "scroll-container" : "scroll-container"}>
                <div className="row coming_soon_text">
                  <div className="col-sm-2">
                    
                  </div>
                  <div id="center_text" className="col-sm-8">
                    I.E. browser support<br/>Coming Soon
                  </div>
                </div>
                <div className="row clocks_row">
                  <div className="col-sm-3">
                    
                  </div>
                  <div id="" className="col-sm-6 clock_col">
                    <Timer/>
                  </div>
                </div>
              </div>
              :
              null
            }
          </div>
        : 
          <div id="" className="scroll-container grain">
            <div className="row coming_soon_text">
              <div id="center_text" className="col-12">
                Mobile support<br/>Coming Soon
              </div>
            </div>
            <div className="row clocks_row">
              <div id="" className="col-12 clock_col">
                <Timer/>
              </div>
            </div>
          </div>
        }
         <div id="#cursorId" className="cursor" ref={div => this.cursorRef = div}></div>
         <div className="cursorHover" ref={div => this.cursorHoverRef = div}></div>
      </div>
    )
  }
}
export default Home;