import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
/*import { NavLink } from "react-router-dom";*/
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
/*import Navbar from "components/Navbars/Navbar.js";*/
import Footer from "components/Footer/Footer.js";
/*import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";*/

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import stylesKit from "assets/jss/material-kit-react/views/componentsSections/navbarsStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
/*import logo from "assets/img/reactlogo.png";*/

/*Nav bar*/
import Header from "components/Header/Header.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
/*import CustomInput from "components/CustomInput/CustomInput.js";
import Search from "@material-ui/icons/Search";*/

let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/") {
        if(prop.path === "/"){
          return (
            <Route
              path={"/" + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return (
          <Route
            path={"/" + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }else{//If ROUTE NOT FOUND REDIRECT to...
        {/*<Redirect path="*" to="/home" />*/}
        return (
          <Redirect path="*" to="/" />
        );
      }
      return null;
    })}
  </Switch>
);

const useStyles = makeStyles(styles);
const useStylesKit = makeStyles(stylesKit)

export default function GeneralLayout({ ...rest }) {
  // styles
  const classes = useStyles();
  const classesKit = useStylesKit();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  //===========================================
  //START Defines if shows HEADER AND FOOTER
  const getRoute = () => {
    //TRUE = Header&Footer; FALSE = NO Header&Footer
    switch (window.location.pathname) {
      case "/":
        return false;
        break;
      case "/coming_soon":
        return false;
        break;
      case "/not_found": 
        return false;
        break;
      default:
        return false;
    }
  };
  //END Defines if shows HEADER AND FOOTER
  //===========================================
  
  //===========================
  //START Handle Mobile size
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  //END Handle Mobile size
  //===========================

  return (
      <div className="testMakeStyles-wrapper-1 ps ps--active-y" ref={mainPanel}>
      {/*<div className={classes.wrapper} ref={mainPanel}>*/}
        {/* ========START Original Sidebar ==========
          <Sidebar
          routes={routes}
          logoText={"Creative Tim"}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          {...rest}
          />
        */}

        {/* ========START ORIGINAL NAVBAR ==========
          <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
          />
            ========END ORIGINAL NAVBAR ==========
        */}
        {getRoute() ? 
        <div id="navbar" className={classesKit.navbar}>
            <Header
              brand="Info Color"
              color="info"
              rightLinks={
                <List className={classesKit.list}>
                  <ListItem className={classesKit.listItem}>
                    <Button
                      href="#home"
                      className={classesKit.navLink}
                      onClick={e => e.preventDefault()}
                      color="transparent"
                    >
                      Home
                    </Button>
                  </ListItem>
                  <ListItem className={classesKit.listItem}>
                    <Button
                      href="#about"
                      className={classesKit.navLink}
                      onClick={e => e.preventDefault()}
                      color="transparent"
                    >
                      About
                    </Button>
                  </ListItem>
                  <ListItem className={classesKit.listItem}>
                    <Button
                      href="#portfolio"
                      className={classesKit.navLink}
                      onClick={e => e.preventDefault()}
                      color="transparent"
                    >
                      Portfolio
                    </Button>
                  </ListItem>
                  <ListItem className={classesKit.listItem}>
                    <Button
                      href="#contact"
                      className={classesKit.navLink}
                      onClick={e => e.preventDefault()}
                      color="transparent"
                    >
                      Contact
                    </Button>
                  </ListItem>
                </List>
              }
            />
        </div> : null}
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className="makeStyles-content-3">
          {/*<div className={classes.content}>*/}
            <div className="makeStyles-container-4">
            {/*<div className={classes.container}>*/}
              {switchRoutes}
            </div>
          </div>
        ) : (
          <div className="testMakeStyles-map-5">{switchRoutes}</div>
          /*<div className={classes.map}>{switchRoutes}</div>*/
        )}
        
        {/*UNCOMMENT if need of FOOTER in all pages
          {getRoute() ? null : null}
        */}

        {/*<FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        />*/}
      </div>
  );
}
